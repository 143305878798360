import React from 'react';

const EncodingSelect = (props) => {
  return <select {...props}>
    <option value="utf8">UTF-8</option>
    <option value="utf16">UTF-16</option>
    <option value="cp932">SJIS (CP932)</option>
    <option value="euc-jp">EUC-JP</option>
  </select>
};

export default EncodingSelect;
