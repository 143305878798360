/**
 * ブラウザにファイルをダウンロードさせる
 */
const download = (name, arrayBuffer) => {
  const blob = new Blob([arrayBuffer], {
    type: 'application/octet-stream',
  });
  const a = document.createElement('a');
  a.setAttribute('href', URL.createObjectURL(blob));
  a.setAttribute('download', name);
  a.click();
  URL.revokeObjectURL(blob);
};

export default download
