import iconv from 'iconv-lite';

/**
 * ファイルのエンコーディングを変換する
 */
const convert = (encIn, encOut) => (arrayBuffer) => {
  const decoded = iconv.decode(Buffer.from(arrayBuffer), encIn);
  if (decoded.length < 1) {
    // TODO テスト
    throw new Error('処理に失敗しました。文字コードを確認してください。');
  }

  const encoded = iconv.encode(decoded, encOut);
  if (encoded.length < 1) {
    // TODO テスト
    throw new Error('処理に失敗しました。文字コードを確認してください。');
  }

  return encoded;
}

export default convert
