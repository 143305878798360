/**
 * ファイル名に suffix を付ける
 */
const rename = (suffix) => (name) => {
  const splitted = name.split('.')
  const extension = splitted.pop()
  return [...splitted, suffix, extension].join('.')
}

export default rename
