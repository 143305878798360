import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import './App.css';
import EncodingSelect from './components/encodingselect';
import convert from './utils/convert'
import download from './utils/download'
import rename from './utils/rename'

function App() {
  const [encIn, setEncIn] = useState('utf8');
  const [encOut, setEncOut] = useState('cp932');
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, [setFiles]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop });

  const onSubmit = (e) => {
    e.preventDefault();
    files.forEach((file) => {
      if (file) {
        const reader = new FileReader();
        reader.addEventListener('load', (event) => {
          const arrayBuffer = convert(encIn, encOut)(reader.result);
          // TODO エラーハンドリング
          download(rename(encOut)(file.name), arrayBuffer);
        });
        reader.readAsArrayBuffer(file);
      }
    });
  };

  let fileMessage = isDragActive ? 'ファイルをドロップしてください' :
    'ファイルをここにドラッグしてください';
  if (files.length > 1) {
    fileMessage = `${files[0].name} 他`;
  } else if (files.length > 0) {
    fileMessage = files[0].name;
  }

  return (
    <div className="App">
      <div className="App-container">
        <header className="App-header"> <h1>文字コード変換</h1> </header>
        <main className="App-main">
          <form onSubmit={onSubmit}>
            <div className="Form-section">
              <div className="Form-field Form-field-half">
                <label htmlFor="encoding-input">変換前</label>
                <EncodingSelect id="encoding-input"
                  className="Form-field-select"
                  defaultValue={encIn} onChange={(event) => {
                  setEncIn(event.target.value);
                }} />
              </div>
              <div className="Form-field Form-field-half">
                <label htmlFor="encoding-output">変換後</label>
                <EncodingSelect id="encoding-output"
                  className="Form-field-select"
                  defaultValue={encOut} onChange={(event) => {
                  setEncOut(event.target.value);
                }} />
              </div>
            </div>
            <div className="Form-field">
              <div className="Form-field-file" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{fileMessage}</p>
              </div>
            </div>
            <div className="Form-field">
              <input type="submit" value="変換" className="Form-field-submit" />
            </div>
          </form>
        </main>
      </div>
      <footer className="Footer">
        <a className="Footer-link" href="https://dyno.design">built by Dyno</a>
        <span className="Footer-unsplash-credit">Photo by <a href="https://unsplash.com/@nbb_photos?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Lacie Slezak</a> on <a href="https://unsplash.com/s/photos/beach?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
      </footer>
    </div>
  );
}

export default App;
